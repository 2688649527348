import React from 'react'

const About = () => {
  return (
    <>
       {/* Hero */}
       <section className='min-vh-50 d-flex justify-content-center align-items-center text-center bg-black' style={{position:"relative"}}>
      <div className="background-img w-100 h-100" style={{backgroundImage: 'url(assets/img/main/24.jpg)', position: 'absolute', opacity: '0.5', top: 0}} />
        <div className="container" style={{zIndex:1}}>
          <div className="row">
              <h1 className='text-light'>About Us</h1>
              <p className='fs-lg text-light'>Green Techno Services and Solutions</p>
          </div>
        </div>
      </section>
      {/* Features */}
      <section className='py-5 text-center bg-secondary'>
        <div className="container">
          <div className="row">
          <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/1.svg" alt="" />
              <p className='fs-lg text-center'>24/7 Service</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/2.svg" alt="" />
              <p className='fs-lg text-center'>Efficient Technology</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/3.svg" alt="" />
              <p className='fs-lg text-center'>Wide Network Coverage</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/4.svg" alt="" />
              <p className='fs-lg text-center'>Highly Skilled Workforce</p>
            </div>
          </div>
        </div>
      </section>
      {/* who are we */}
      <section className='py-5 py-lg-7'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12">
                <h1>Who we are?</h1>
                <p>Green Techno Services and Solution is the team of Professionals, enthusiast, and highly passionate people whose aim is to grow through the path of Sustainability by providing the services like Tank Cleaning (STP, ETP, WTP) and Repair of Organic Composting Machines.</p>
              </div>
              <div className="col-lg-6 col-sm-12 mt-3 mt-lg-0">
                <img className='rounded w-100' src="/assets/img/main/13.jpg" alt="" />
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default About