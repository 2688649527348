import React from 'react'

const Portfolio = () => {

  const portfolio = [
    {url:"19.jpg"},
    {url:"20.jpg"},
    {url:"21.jpg"},
  ]

  return (
    <>
       <section className="position-relative bg-dark pt-7 pb-5 pb-md-7 bg-size-cover bg-attachment-fixed" style={{backgroundImage: 'url(img/demo/web-studio/cubes-bg.jpg)'}}>
        <div className="shape shape-bottom shape-curve bg-body">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
            <path fill="currentColor" d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
          </svg>
        </div>
        <div className="container position-relative zindex-5 text-center pt-md-6 pt-lg-7 py-5 my-lg-3">
          <h1 className="text-light mb-0">Our Portfolio</h1>
        </div>
      </section>
      {/* Portfolio images */}
      <section className='py-7'>
        <div className="container">
          <div className="row">
              {portfolio.map((item, index)=>(
                <div className="col-lg-4 col-sm-12 mt-3 mt-lg-3">
                <img className='rounded image-object-fit' src={`assets/img/main/${item.url}`} style={{height:"450px"}} alt="" />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio