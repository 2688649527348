import React from 'react'

const Contact = () => {
  return (
    <>
        <section className="jarallax bg-gradient pt-5 pb-7 pt-md-7" data-jarallax data-speed="0.25"
          style={{backgroundImage: 'none'}}><span
            className="position-absolute top-0 start-0 w-100 h-100 bg-gradient opacity-80" />
          <div className="container position-relative zindex-5 pt-3 pb-7 pt-md-0">
            <div className="row justify-content-center py-md-5">
              <div className="col-lg-6 col-md-8 text-center pt-2 mb-n3">
                <h1 className="text-light">Contacts</h1>
                <p className="text-light">Please feel free to ask your queries. We'll be happy to answer them.
Contact form queries are answered usually within 24hrs.</p>
              </div>
            </div>
          </div>
          <div id="jarallax-container-0"
            style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: -100}}>
            <div className="jarallax-img"
              style={{backgroundImage: 'url("img/pages/contacts/page-title-bg.jpg")', objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'fixed', top: '0px', left: '0px', width: '1781.25px', height: '374.227px', overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', willChange: 'transform, opacity', marginTop: '-21.6133px', transform: 'translate3d(0px, 41.3545px, 0px)'}}
              data-jarallax-original-styles="background-image: url(img/pages/contacts/page-title-bg.jpg);" />
          </div>
        </section>
        {/* cards */}
        <section className="container-fluid bg-overlay-content mb-5 mb-md-6" style={{marginTop: '-120px'}}>
        <div className="row">
          <div className="col-lg-3 col-sm-6 mb-grid-gutter">
            <div className="card h-100 border-0 shadow-lg py-4">
              <div className="card-body text-center"><i className="ai-map-pin mb-4 text-primary" style={{fontSize: '2.25rem'}} />
                <h3 className="h6 mb-2">Address, Location</h3>
                <p className="fs-sm mb-2">Dehugaon, Pune, Maharashtra, India, 412109</p><a className="fancy-link fs-sm" href="#map" data-scroll>See on the map</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mb-grid-gutter">
            <div className="card h-100 border-0 shadow-lg py-4">
              <div className="card-body text-center"><i className="ai-clock mb-4 text-danger" style={{fontSize: '2.25rem'}} />
                <h3 className="h6 mb-2">Working hours</h3>
                <ul className="list-unstyled fs-sm mb-0">
                  <li>Mon - Fri: 9AM - 8PM </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mb-grid-gutter">
            <div className="card h-100 border-0 shadow-lg py-4">
              <div className="card-body text-center"><i className="ai-phone mb-4 text-success" style={{fontSize: '2.25rem'}} />
                <h3 className="h6 mb-2">Phone numbers</h3>
                <ul className="list-unstyled fs-sm mb-0">
                  <li><span className="me-1">Customer service:</span><a className="nav-link-style" href="tel:9922677105">+91 9922677105</a></li>
                  <li><span className="me-1">Tech support:</span><a className="nav-link-style" href="tel:9075007877">+91 9075007877</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mb-grid-gutter">
            <div className="card h-100 border-0 shadow-lg py-4">
              <div className="card-body text-center"><i className="ai-mail mb-4 text-warning" style={{fontSize: '2.25rem'}} />
                <h3 className="h6 mb-2">Email addresses</h3>
                <ul className="list-unstyled fs-sm mb-0">
                  <li><a className="nav-link-style" href="mailto:gtssolutions19@gmail.com">gtssolutions19@gmail.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact form */}
      <section className="border-top" id="map">
        <div className="row g-0 gallery" lg-uid="lg0"><a className="col-lg-6 gallery-item map-popup d-flex flex-columnt justify-content-center align-items-center bg-position-center bg-size-cover py-7 text-center" href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.91476818218!2d-74.11976253858133!3d40.69740344296443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sua!4v1568574342685!5m2!1sen!2sua" data-iframe="true" data-sub-html="<h6 class=&quot;fs-sm text-light&quot;>396 Lillian Blvd, Holbrook, NY 11741</h6>" style={{backgroundImage: 'url(assets/img/pages/contacts/map02.jpg)'}}><span className="gallery-caption"><i className="ai-maximize-2 fs-xl mt-n1 me-2" />Expand the map</span>
            <div className="d-inline-block py-4 py-md-6"><img src="assets/img/pages/contacts/marker.png" alt="Map marker" width={48} /></div></a>
          <div className="col-lg-6 px-3 px-lg-5">
            <div className="pt-5 pb-6 pt-md-6 mx-auto" style={{maxWidth: '720px'}}>
              <h2 className="h3 pb-4">Drop us a line</h2>
              <form action="https://bending.tech/clients/gtss/enquiry.php" method="post" className="contact-form php-email-form1">
        <div className="row gy-4 form-group">
          <div className="col-md-6 my-1">
            <label className="form-label" htmlFor="cont-fn">Full name<sup className="text-danger ms-1">*</sup></label>
            <input type="text" name="name" className="form-control" placeholder="Your Name" required />
          </div>
          <div className="col-md-6 form-group my-1">
            <label className="form-label" htmlFor="cont-email">Email address<sup className="text-danger ms-1">*</sup></label>
            <input type="email" className="form-control" name="email" placeholder="Your Email" required />
          </div>
          <div className="col-md-12 form-group my-1">
            <label className="form-label" htmlFor="cont-phone">Phone number</label>
            <input type="text" className="form-control" name="phone" placeholder="Phone No." required />
          </div>
          <div className="col-md-12 form-group my-1"><grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="cGcvT" /><grammarly-extension data-grammarly-shadow-root="true" style={{mixBlendMode: 'darken', position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="cGcvT" />
            <label className="form-label" htmlFor="cont-message">Message<sup className="text-danger ms-1">*</sup></label>
            <textarea className="form-control" name="message" rows={6} placeholder="Message" required spellCheck="false" defaultValue={""} />
          </div>
          <div className="col-md-12 text-center my-3">
            <button className="btn btn-primary btn-lg" type="submit" name="btn-send"> Send Message</button>
          </div>
        </div>
      </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact