import React from 'react'
import { Link } from 'react-router-dom'

const FormSuccessfull = () => {
  return (
    <section className='vh-100 vw-100 bg-white d-flex align-items-center text-center justify-content-center' style={{zIndex:100, position:"fixed", top:"0px", left:"0px"}}>
        <div className="container">
            <h1 style={{fontSize:"8rem"}}><i className='ai-check text-success fw-bolder'></i></h1>
            <h1>Form Submitted Successfully</h1>
            <p>Thanks for asking! We'll be happy to answer your questions. It's usually done within 24hrs.</p>
            <Link to="/" className='btn btn-success btn-lg mt-3'>Continue Browsing</Link>
        </div>
    </section>
  )
}

export default FormSuccessfull