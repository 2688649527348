import React from 'react'

const Services = () => {
  return (
    <>
      <section className='min-vh-50' style={{position:"relative"}}>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false" style={{backgroundColor:"black"}}>
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/img/main/26.jpg" className="d-block w-100" style={{height:"50vh", opacity:"0.5"}} alt="..." />
              <div className="carousel-caption d-none d-md-block position-absolute" style={{top:"25%", left:"25%"}}>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/img/main/27.jpg" className="d-block w-100" style={{height:"50vh", opacity:"0.5"}} alt="..." />
              <div className="carousel-caption d-none d-md-block position-absolute" style={{top:"25%", left:"25%"}}>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/img/main/28.jpg" className="d-block w-100" style={{height:"50vh", opacity:"0.5"}} alt="..." />
              <div className="carousel-caption d-none d-md-block position-absolute" style={{top:"25%", left:"25%"}}>
              </div>
            </div>
            <div className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center text-center' style={{left:0, top:0}}>
              <h2 className='text-white'>Services Offered by <br /> Green Techno Services and Solution</h2>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/*  */}
      </section>
       {/* <section className='min-vh-50 d-flex align-items-center position-relative bg-black'>
       <div className="background-img w-100 h-100" style={{backgroundImage: 'url(assets/img/main/14.jpg)', position: 'absolute', opacity: '0.7', top: 0}} />
        <div className="container" style={{zIndex:1}}>
          <div className="row">
              <h1 className='text-light'>Services offered by <br /> Green Techno Services and Solutions</h1>
          </div>
          <button className="mt-2 btn btn-primary">Read More</button>
        </div>
      </section> */}
      {/* Tank Cleaning */}
      <section className='pt-5 pt-lg-7' id='tankcleaning'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12 order-lg-2">
                <h1>Tank Cleaning <br /> (STP, ETP & WTP)</h1>
                <p>Green Techno Services and Solution provide all kind of STP, ETP & WTP tank disposal and cleaning Services. We have the team of profesionals that uses upgraded technology for tank cleaning. Including different level cleaning process, We use advanced imported machines and proprietary antiseptic agents which are effective, secure and much more significantly eco friendly.</p>
              </div>
              <div className="col-lg-6 col-sm-12 mt-3 mt-lg-0">
                <img className='rounded w-100' src="/assets/img/main/15.jpg" alt="" />
              </div>
            </div>
          </div>
      </section>
        {/* Reparing Organic Compost Machines */}
        <section className='py-5 py-lg-7' id='organiccompost'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12">
                <h1>Reparing Organic Compost Machines</h1>
                <p>Green Techno Services and Solution also provide repairing service of Organic Composting Machines, We have skilled and enthusiast workforce that is proefficient it’s tasks of repairing organic composting machines.</p>
              </div>
              <div className="col-lg-6 col-sm-12 mt-3 mt-lg-0">
                <img className='rounded w-100' src="/assets/img/main/28.jpg" alt="" />
              </div>
            </div>
          </div>
      </section>
    </>
  )
}

export default Services