import React from 'react'

const Footer = () => {
  return (
    <footer className="footer bg-dark pt-5 pb-4">
    <div className="container pb-2 text-center text-md-start">
      <div className="row pt-md-2 pb-3">
        <div className="col-lg-3 col-md-4 pb-4"><a className="d-inline-block mb-3" href="index.html" style={{width: '153px'}}>
          <img src="assets/img/logo.png" style={{height:"50px"}} alt="GT" />
        </a>
          <p className="fs-sm text-light opacity-50 mb-0">Green Techno Services and Solutions</p>
        </div>
        <div className="col-md-5 pt-2 pb-4">
          <div className="widget widget-light">
            <h4 className="widget-title mb-3">Support</h4>
            <div className="d-lg-flex">
              <ul className="me-lg-5">
                <li><a className="widget-link" href="mailto:gtssolutions19@gmail.com"><i className="ai-mail fs-lg me-2" />gtssolutions19@gmail.com</a></li>
              </ul>
              <ul>
                <li><a className="widget-link" href="tel:9922677105"><i className="ai-phone fs-lg me-2" />+91 9922677105</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3 pt-2 pb-4">
          <div className="widget widget-light">
            <h4 className="widget-title mb-3">Follow</h4><a className="btn-social bs-outline bs-light bs-facebook bs-lg me-2 mb-2" href="#"><i className="ai-facebook" /></a><a className="btn-social bs-outline bs-light bs-twitter bs-lg me-2 mb-2" href="#"><i className="ai-twitter" /></a><a className="btn-social bs-outline bs-light bs-instagram bs-lg me-2 mb-2" href="#"><i className="ai-instagram" /></a><a className="btn-social bs-outline bs-light bs-google bs-lg mb-2" href="#"><i className="ai-google" /></a>
          </div>
        </div>
      </div>
      <p className="fs-sm text-center mb-0"><span className="text-light opacity-50">Copyright © 2022 Green Techno Services and Solutions</span></p>
    </div>
  </footer>
  )
}

export default Footer