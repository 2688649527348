import React from 'react'
import { Link } from 'react-router-dom'
import Portfolio from './Portfolio'

const Homepage = () => {

  const portfolio = [
    {url:"3.jpg"},
    {url:"4.jpg"},
    {url:"5.jpg"},
    {url:"6.jpg"},
    {url:"7.jpg"},
    {url:"8.jpg"},
    {url:"9.jpg"},
    {url:"10.jpg"},
  ]

  return (
    <>
      {/* Hero */}
      <section className='min-vh-50' style={{position:"relative"}}>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false" style={{backgroundColor:"black"}}>
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/img/main/26.jpg" className="d-block w-100 image-object-fit" style={{height:"50vh", opacity:"0.5"}} alt="..." />
              <div className="carousel-caption d-none d-md-block position-absolute" style={{top:"25%", left:"25%"}}>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/img/main/27.jpg" className="d-block w-100 image-object-fit" style={{height:"50vh", opacity:"0.5"}} alt="..." />
              <div className="carousel-caption d-none d-md-block position-absolute" style={{top:"25%", left:"25%"}}>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/img/main/28.jpg" className="d-block w-100 image-object-fit" style={{height:"50vh", opacity:"0.5"}} alt="..." />
              <div className="carousel-caption d-none d-md-block position-absolute" style={{top:"25%", left:"25%"}}>
              </div>
            </div>
            <div className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center flex-column text-center' style={{left:0, top:0}}>
              <h2 className='text-white'>Green Techno Services and Solution</h2>
              <p className='text-white pb-0'>Tank Cleaning (STP, ETP, WTP) And Compost Machine Repairing</p>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/*  */}
      </section>
      {/* Features */}
      <section className='py-7 text-center'>
        <div className="container">
          <div className="row">
            <div className="mb-5">
            <h1>About Green Techno Services and Solutions</h1>
            <p>Green Techno Services and Solution is the team of Professionals, enthusiast, and highly passionate people whose aim is to grow through the path of Sustainability by providing the services like Tank Cleaning (STP, ETP, WTP) and Repair of Organic Composting Machines.</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/1.svg" alt="" />
              <p className='fs-lg text-center'>24/7 Service</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/2.svg" alt="" />
              <p className='fs-lg text-center'>Efficient Technology</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/3.svg" alt="" />
              <p className='fs-lg text-center'>Wide Network Coverage</p>
            </div>
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center flex-column mt-3 mt-lg-0">
              <img className='mb-3' src="/assets/img/icons/4.svg" alt="" />
              <p className='fs-lg text-center'>Highly Skilled Workforce</p>
            </div>
          </div>
        </div>
      </section>
      {/* Offerings */}
      <section className=" text-center px-2">
          <div className="container">
              <h1 className='mb-5'>Our Services</h1>
              <div className="row align-items-center justify-content-center g-4">
                <div className="col-lg-4 col-sm-12 rounded border shadow px-0">
                  <a href={"/services#tankcleaning"} style={{textDecoration:"none"}}>
                    <img src="assets/img/main/2.jpg" className='image-object-fit w-100' style={{height:"450px"}} alt="" />
                    <div className='py-4 fw-bold text-dark'>Tank Cleaning (STP, ETP, & WTP)</div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-12 rounded border shadow px-0">
                  <a href={"/services#organiccompost"} style={{textDecoration:"none"}}>
                    <img src="assets/img/main/1.jpg" className='image-object-fit w-100' style={{height:"450px"}} alt="" />
                    <div className='py-4 fw-bold text-dark'>Repairing of Organic Composting Machine</div>
                  </a>
                </div>
            </div>
          </div>        
      </section>
      {/* Our Services */}
      <section className='py-7 text-center'>
        <div className="container-fluid px-3 px-lg-7">
          <div className="row">
            <h1 className='mb-5'>Related Projects</h1>
            {portfolio.map((item, index)=>(
              <div className="col-lg-3 col-sm-6 mt-3 mt-lg-3" key={index}>
                <Link to={"/portfolio"}>
                  <img className='w-100 image-object-fit rounded' style={{height:"450px"}} src={`/assets/img/main/${item.url}`} alt="" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Homepage