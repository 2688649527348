import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
          <header className="navbar navbar-expand-lg navbar-light bg-light navbar-shadow">
            <div className="container px-0 px-xl-3">
              <a className="navbar-brand order-lg-1 me-0 pe-lg-2 me-lg-4" href="/">
                <img src="assets/img/logo.png" style={{height:"50px"}} alt="GT" />
              </a>
              <div className="d-flex align-items-center order-lg-3">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse1">
                  <span className="navbar-toggler-icon" />
                </button>
                <NavLink className="btn btn-primary d-none d-sm-inline-block ms-3" to="/contact">Contact Us</NavLink>
              </div>
              <div className="collapse navbar-collapse order-lg-2" id="navbarCollapse1">
                <ul className="navbar-nav me-auto">
                <li className="nav-item active"><NavLink className="nav-link" to={"/"}>Home</NavLink></li>
                <li className="nav-item active"><NavLink className="nav-link" to={"/about"}>About</NavLink></li>
                <li className="nav-item active"><NavLink className="nav-link" to={"/services"}>Services</NavLink></li>
                <li className="nav-item active"><NavLink className="nav-link" to={"/portfolio"}>Portfolio</NavLink></li>
                </ul>
              </div>
            </div>
          </header>
  )
}

export default Navbar